import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'

export default () => {
  return (
    <Wrapper subtitle='Kontakt'>
      <h1>Es tut uns leid</h1>

      <p>
        Leider ist bei Ihrer Kontaktanfrage etwas schiefgelaufen. Bitte versuchen Sie es noch einmal oder
        senden sie eine E-Mail direkt an kontakt@rst-info.de.
      </p>
    </Wrapper>
  )
}
